import styled from "styled-components";

export const StyledLink = styled("a")`
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
  padding: 13px 15px;
  cursor: pointer;
  max-width: 180px;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border-bottom: 6px solid #ebd552;
  }
`;
