import styled from "styled-components";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

export const StyledNavbar = styled("div")`
  display: flex;
  position: absolute;
  top: 60px;
  right: 30px;
  z-index: 2;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }
  cursor: pointer;
  display: none;
  color: white;
  svg {
    fill: white;
  }
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
`;
export const CloseOutline = styled(CloseOutlined)`
  font-size: 22px;
  color: white;
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;
