import styled from "styled-components";

export const StyledFooter = styled("div")`
  background-color: var(--color-primary);
  min-height: 500px;
  padding-bottom: 50px;
`;

export const StyledLinkIcon = styled("a")`
  color: white;
  font-size: 40px;
  padding-left: 10px;
  :hover {
    color: var(--color-secondary);
  }
`;

export const Contact = styled("a")`
  color: var(--color-text-primary);
  display: inline-block;
  border: solid 2px var(--color-text-primary);
  width: fit-content;
  padding: 7px 10px 7px 10px;

  :hover {
    color: var(--color-text-primary);
    border: solid 2px var(--color-secondary);
  }
`;

export const Icon = styled("span")`
  font-size: 36px;
`;

export const Container = styled("div")`
  padding-top: 100px;
  padding-bottom: 50px;
  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 50px;
  }
`;

export const ContactsGrid = styled("div")`
  display: grid;
  grid-template-columns: 40px 250px;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
`;
