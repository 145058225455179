import React from "react";
import {
  StyledFooter,
  Container,
  StyledLinkIcon,
  Contact,
  Icon,
  ContactsGrid
} from "./styles";
import { Row, Col } from "antd";
import { contacts, followUs, openingTimes } from "../../content/footer";

export default function index(props) {
  return (
    <StyledFooter id={props.id}>
      <Row justify="space-around" align="top">
        <Col lg={5} md={10} sm={24} xs={24}>
          <Contacts />
        </Col>
        <Col lg={6} md={8} sm={24} xs={24}>
          <OpeningTimes />
        </Col>
        <Col lg={3} md={6} sm={24} xs={24}>
          <FollowUs />
        </Col>
      </Row>
    </StyledFooter>
  );
}

const Contacts = () => {
  return (
    <Container>
      <h2>Contatti</h2>
      <ContactsGrid>
        <Icon className="fa fa-whatsapp"></Icon>
        <Contact href={`https://wa.me/${contacts.whatsapp.link}`}>
          {contacts.whatsapp.label}
        </Contact>
        <Icon className="fa fa-phone"></Icon>

        <Contact href={`tel:${contacts.tel}`}>{contacts.tel}</Contact>
        <Icon className="fa fa-envelope"></Icon>
        <Contact href={`mailto:${contacts.email}`}>{contacts.email}</Contact>
      </ContactsGrid>
    </Container>
  );
};

const OpeningTimes = () => {
  return (
    <Container>
      <h2>Orari</h2>
      {openingTimes.map(time => (
        <Row
          key={time.label}
          justify="start"
          gutter={10}
          align="top"
          wrap={false}
        >
          <Col xs={8} lg={18} xl={12}>
            {time.label}
          </Col>
          <Row justify="space-between" gutter={10} align="top" wrap={false}>
            <Col xs={12} lg={18} xl={12}>
              {time.from}
            </Col>
            <Col xs={12} lg={18} xl={12}>
              {time.to}
            </Col>
          </Row>
        </Row>
      ))}
      <br />
      <ContactsGrid>
        <Icon className="fa fa-map-marker"></Icon>
        <Contact
          href={`https://www.google.com/maps/search/?api=1&query=Ristorante+Pizzeria+La+Fonte+Kilometrozero`}
          target="_blank"
          rel="noreferrer"
        >
          <Row wrap={false}>{contacts.addressLine1}</Row>
          <Row wrap={false}>{contacts.addressLine2}</Row>
        </Contact>
      </ContactsGrid>
    </Container>
  );
};

const FollowUs = () => {
  return (
    <Container>
      <h2>Seguici</h2>
      {followUs.map(social => (
        <StyledLinkIcon
          key={social.id}
          href={social.href}
          className={social.iconClass}
          alt={social.href}
          aria-label={social.id}
        ></StyledLinkIcon>
      ))}
    </Container>
  );
};
