import { Drawer } from "antd";
import { CloseOutline } from "./styles";

const style = { bodyStyle: { backgroundColor: "black" } };

const StyledDrawer = ({ children, visible, onClose }) => (
  <Drawer
    closable={true}
    visible={visible}
    onClose={onClose}
    bodyStyle={style.bodyStyle}
    closeIcon={<CloseOutline />}
  >
    {children}
  </Drawer>
);

export default StyledDrawer;
