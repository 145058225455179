const routes = [
  /*  {
    path: ["/instaAuth"],
    exact: true,
    component: "InstagramAuth"
  }, */
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home"
  }
];

export default routes;
