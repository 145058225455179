export const contacts = {
  tel: "+39 0874 503542",
  whatsapp: { link: "393339884468", label: "+39 333 9884468" },
  email: "info@lafontekilometrozero.it",
  addressLine1: "Strada Statale 234",
  addressLine2: "86010 Castropignano (CB)"
};

export const openingTimes = [
  { label: "Lunedí", from: "09:00", to: "24:00" },
  { label: "Martedí", from: "Chiuso", to: "" },
  { label: "Mercoledí", from: "09:00", to: "24:00" },
  { label: "Giovedí", from: "09:00", to: "24:00" },
  { label: "Venerdí", from: "09:00", to: "24:00" },
  { label: "Sabato", from: "09:00", to: "01:00" },
  { label: "Domenica", from: "09:00", to: "24:00" }
];

export const followUs = [
  {
    id: "instagram",
    iconClass: "fa fa-instagram",
    href: "https://www.instagram.com/lafontekilometrozero/"
  },
  {
    id: "facebook",
    iconClass: "fa fa-facebook",
    href: "https://www.facebook.com/Ristorantelafontekilometrozero"
  }
];
