const links = [
  { id: "pizza", name: "Pizza", to: "pizza" },
  { id: "ingredienti", name: "Ingredienti", to: "products" },
  { id: "menu", name: "Menu", to: "menu" },
  { id: "location", name: "Location", to: "location" },
  { id: "gallery", name: "Galleria", to: "gallery" },
  { id: "contatti", name: "Contatti", to: "contacts" }
];

export default links;
