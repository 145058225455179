import React, { useState } from "react";
import StyledDrawer from "../../common/Drawer";
import Link from "../../common/Link";
import { StyledNavbar, Burger, Outline, NotHidden } from "./styles";
import links from "./config";

const Navbar = () => {
  const [visible, setVisibility] = useState(false);
  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const scrollTo = id => {
    const element = document.getElementById(id);
    element &&
      element.scrollIntoView({
        behavior: "smooth"
      });
    setVisibility(false);
  };

  const Links = () =>
    links.map(link => (
      <Link key={link.id} href={`#${link.to}`} onClick={e => scrollTo(link.to)}>
        {link.name}
      </Link>
    ));

  return (
    <StyledNavbar>
      <Burger onClick={showDrawer}>
        <Outline />
      </Burger>
      <NotHidden>
        <Links />
      </NotHidden>
      <StyledDrawer closable={true} visible={visible} onClose={onClose}>
        <Links />
      </StyledDrawer>
    </StyledNavbar>
  );
};

export default Navbar;
